var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'membership',
    'date',
    'amount',
    'concept',
    'note' ],"formats":{
    date: 'Date',
    amount: 'Money',
  },"itemsPerPage":"10","searchOnStart":true,"table":"dues"}})}
var staticRenderFns = []

export { render, staticRenderFns }